var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "page-title-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "page-title-inner dashboard__header" }, [
          _c("div", { staticClass: "page-title dashboard__header-title" }, [
            _vm._v("Dashboard")
          ]),
          _c("div", { staticClass: "dashboard__header-date" }, [
            _vm._v(_vm._s(_vm.moment().format("DD MMM YYYY")))
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "dashboard__main-top" }, [
        _c("div", { staticClass: "dashboard__block" }, [
          _vm._m(0),
          _vm.stats.todayOpen
            ? _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v(
                  " $ " +
                    _vm._s(_vm.stats.todayOpen.toLocaleString("en-CA")) +
                    " "
                )
              ])
            : _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v("$ 0")
              ])
        ]),
        _c("div", { staticClass: "dashboard__block" }, [
          _vm._m(1),
          _vm.stats.todayDone
            ? _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v(
                  " $ " +
                    _vm._s(_vm.stats.todayDone.toLocaleString("en-CA")) +
                    " "
                )
              ])
            : _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v("$ 0")
              ])
        ]),
        _c("div", { staticClass: "dashboard__block" }, [
          _vm._m(2),
          _vm.stats.thisWeekDone
            ? _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v(
                  " $ " +
                    _vm._s(_vm.stats.thisWeekDone.toLocaleString("en-CA")) +
                    " "
                )
              ])
            : _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v("$ 0")
              ])
        ]),
        _c("div", { staticClass: "dashboard__block" }, [
          _vm._m(3),
          _vm.stats.thisMonthDone
            ? _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v(
                  " $ " +
                    _vm._s(_vm.stats.thisMonthDone.toLocaleString("en-CA")) +
                    " "
                )
              ])
            : _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v("$ 0")
              ])
        ]),
        _c("div", { staticClass: "dashboard__block" }, [
          _vm._m(4),
          _vm.stats.thisYearDone
            ? _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v(
                  " $ " +
                    _vm._s(_vm.stats.thisYearDone.toLocaleString("en-CA")) +
                    " "
                )
              ])
            : _c("div", { staticClass: "dashboard__block-price" }, [
                _vm._v("$ 0")
              ])
        ])
      ]),
      _c("div", { staticClass: "dashboard__main-middle" }, [
        _c("div", { staticClass: "dashboard__stats" }, [
          _c("div", { staticClass: "dashboard__stats-header" }, [
            _c(
              "div",
              {
                staticClass: "dashboard__stats-link",
                class: {
                  "dashboard__stats-link--active": _vm.statsComponent === "week"
                },
                on: {
                  click: function($event) {
                    return _vm.changeStats("week")
                  }
                }
              },
              [
                _vm._v(" This Week "),
                _vm.statsComponent === "week"
                  ? _c("div", { staticClass: "dashboard__stats-link--line" })
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "dashboard__stats-link",
                class: {
                  "dashboard__stats-link--active":
                    _vm.statsComponent === "month"
                },
                on: {
                  click: function($event) {
                    return _vm.changeStats("month")
                  }
                }
              },
              [
                _vm._v(" This Month "),
                _vm.statsComponent === "month"
                  ? _c("div", { staticClass: "dashboard__stats-link--line" })
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "dashboard__stats-link",
                class: {
                  "dashboard__stats-link--active": _vm.statsComponent === "year"
                },
                on: {
                  click: function($event) {
                    return _vm.changeStats("year")
                  }
                }
              },
              [
                _vm._v(" This Year "),
                _vm.statsComponent === "year"
                  ? _c("div", { staticClass: "dashboard__stats-link--line" })
                  : _vm._e()
              ]
            )
          ]),
          _vm.statsComponent === "week"
            ? _c(
                "div",
                { staticClass: "dashboard__stats-main" },
                _vm._l(_vm.daysOfWeek, function(day, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "dashboard__stats-row" },
                    [
                      _c("div", { staticClass: "dashboard__stats-row-title" }, [
                        _vm._v(_vm._s(day))
                      ]),
                      _c("div", { staticClass: "dashboard__stats-row-price" }, [
                        _vm._v("$ " + _vm._s(_vm.weekStats[i]))
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm.statsComponent === "month"
            ? _c(
                "div",
                { staticClass: "dashboard__stats-main" },
                _vm._l(_vm.weekOfMonth, function(weekName, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "dashboard__stats-row" },
                    [
                      _c("div", { staticClass: "dashboard__stats-row-title" }, [
                        _vm._v(_vm._s(weekName))
                      ]),
                      _c("div", { staticClass: "dashboard__stats-row-price" }, [
                        _vm._v("$ " + _vm._s(_vm.monthStats[i]))
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm.statsComponent === "year"
            ? _c(
                "div",
                { staticClass: "dashboard__stats-main" },
                _vm._l(_vm.monthsOfYear, function(montName, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "dashboard__stats-row" },
                    [
                      _c("div", { staticClass: "dashboard__stats-row-title" }, [
                        _vm._v(_vm._s(montName))
                      ]),
                      _c("div", { staticClass: "dashboard__stats-row-price" }, [
                        _vm._v("$ " + _vm._s(_vm.yearStats[i]))
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "dashboard__chart" },
          [
            _c("apexchart", {
              attrs: {
                type: "line",
                options: _vm.chartOptions,
                series: _vm.series,
                height: "400"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "dashboard__main-finance" }, [
        _c(
          "div",
          { staticClass: "finance__title" },
          [
            _c("span", [_vm._v("Select Year")]),
            _c("date-picker", {
              staticClass: "dashboard-year",
              attrs: {
                type: "year",
                format: "YYYY",
                "value-type": "format",
                editable: false,
                "popup-class": "dashboard-year",
                placeholder: "Select year"
              },
              on: { change: _vm.selectYear },
              model: {
                value: _vm.financeYear,
                callback: function($$v) {
                  _vm.financeYear = $$v
                },
                expression: "financeYear"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "finance__table" }, [
          _vm._m(5),
          _c(
            "div",
            { staticClass: "finance__table-main" },
            _vm._l(_vm.financeStats, function(fin, idx) {
              return _c(
                "div",
                {
                  key: idx + fin.label,
                  staticClass: "finance__table-main-row"
                },
                [
                  _c("div", { staticClass: "finance__table-main-cell" }, [
                    _vm._v(_vm._s(fin.label))
                  ]),
                  _c("div", { staticClass: "finance__table-main-cell" }, [
                    _vm._v(_vm._s(fin.serviceTotal) + " $")
                  ]),
                  _c("div", { staticClass: "finance__table-main-cell" }, [
                    _vm._v(_vm._s(fin.hstTotal) + " $")
                  ]),
                  _c(
                    "div",
                    { staticClass: "finance__table-main-cell is-last" },
                    [_vm._v(_vm._s(fin.grandTotal) + " $")]
                  )
                ]
              )
            }),
            0
          )
        ]),
        _c(
          "div",
          { staticClass: "finance__cards" },
          _vm._l(_vm.financeStats, function(fin, idx) {
            return _c(
              "div",
              { key: idx + fin.label, staticClass: "finance__card" },
              [
                _c("div", { staticClass: "finance__card-title" }, [
                  _vm._v(_vm._s(fin.label))
                ]),
                _c("div", { staticClass: "finance__card-row" }, [
                  _c("div", { staticClass: "finance__card-cell is-title" }, [
                    _vm._v("Service Total")
                  ]),
                  _c("div", { staticClass: "finance__card-cell" }, [
                    _vm._v(_vm._s(fin.serviceTotal) + " $")
                  ])
                ]),
                _c("div", { staticClass: "finance__card-row" }, [
                  _c("div", { staticClass: "finance__card-cell is-title" }, [
                    _vm._v("HST Total")
                  ]),
                  _c("div", { staticClass: "finance__card-cell" }, [
                    _vm._v(_vm._s(fin.hstTotal) + " $")
                  ])
                ]),
                _c("div", { staticClass: "finance__card-row" }, [
                  _c("div", { staticClass: "finance__card-cell is-title" }, [
                    _vm._v("Grand Total")
                  ]),
                  _c("div", { staticClass: "finance__card-cell" }, [
                    _vm._v(_vm._s(fin.grandTotal) + " $")
                  ])
                ])
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard__block-title-wrapper" }, [
      _c("div", { staticClass: "dashboard__block-title" }, [
        _vm._v("Today Open")
      ]),
      _c("div", {
        staticClass:
          "dashboard__block-indicator dashboard__block-indicator--grey"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard__block-title-wrapper" }, [
      _c("div", { staticClass: "dashboard__block-title" }, [
        _vm._v("Today Done")
      ]),
      _c("div", {
        staticClass:
          "dashboard__block-indicator dashboard__block-indicator--green"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard__block-title-wrapper" }, [
      _c("div", { staticClass: "dashboard__block-title" }, [
        _vm._v("This Week Done")
      ]),
      _c("div", {
        staticClass:
          "dashboard__block-indicator dashboard__block-indicator--yellow"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard__block-title-wrapper" }, [
      _c("div", { staticClass: "dashboard__block-title" }, [
        _vm._v("This Month Done")
      ]),
      _c("div", {
        staticClass:
          "dashboard__block-indicator dashboard__block-indicator--orange"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard__block-title-wrapper" }, [
      _c("div", { staticClass: "dashboard__block-title" }, [
        _vm._v("This Year Done")
      ]),
      _c("div", {
        staticClass:
          "dashboard__block-indicator dashboard__block-indicator--blue"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "finance__table-header" }, [
      _c("div", { staticClass: "finance__table-header-cell" }, [
        _vm._v("Month")
      ]),
      _c("div", { staticClass: "finance__table-header-cell" }, [
        _vm._v("Service Total")
      ]),
      _c("div", { staticClass: "finance__table-header-cell" }, [
        _vm._v("HST Total")
      ]),
      _c("div", { staticClass: "finance__table-header-cell is-last" }, [
        _vm._v("Grand Total")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }