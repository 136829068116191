<template>
  <div class="page-wrapper">
    <div class="page-title-wrapper">
      <div class="container">
        <div class="page-title-inner dashboard__header">
          <div class="page-title dashboard__header-title">Dashboard</div>
          <div class="dashboard__header-date">{{ moment().format('DD MMM YYYY') }}</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="dashboard__main-top">
        <div class="dashboard__block">
          <div class="dashboard__block-title-wrapper">
            <div class="dashboard__block-title">Today Open</div>
            <div class="dashboard__block-indicator dashboard__block-indicator--grey"></div>
          </div>
          <div v-if="stats.todayOpen" class="dashboard__block-price">
            $ {{ stats.todayOpen.toLocaleString('en-CA') }}
          </div>
          <div v-else class="dashboard__block-price">$ 0</div>
        </div>
        <div class="dashboard__block">
          <div class="dashboard__block-title-wrapper">
            <div class="dashboard__block-title">Today Done</div>
            <div class="dashboard__block-indicator dashboard__block-indicator--green"></div>
          </div>
          <div v-if="stats.todayDone" class="dashboard__block-price">
            $ {{ stats.todayDone.toLocaleString('en-CA') }}
          </div>
          <div v-else class="dashboard__block-price">$ 0</div>
        </div>
        <div class="dashboard__block">
          <div class="dashboard__block-title-wrapper">
            <div class="dashboard__block-title">This Week Done</div>
            <div class="dashboard__block-indicator dashboard__block-indicator--yellow"></div>
          </div>
          <div v-if="stats.thisWeekDone" class="dashboard__block-price">
            $ {{ stats.thisWeekDone.toLocaleString('en-CA') }}
          </div>
          <div v-else class="dashboard__block-price">$ 0</div>
        </div>
        <div class="dashboard__block">
          <div class="dashboard__block-title-wrapper">
            <div class="dashboard__block-title">This Month Done</div>
            <div class="dashboard__block-indicator dashboard__block-indicator--orange"></div>
          </div>
          <div v-if="stats.thisMonthDone" class="dashboard__block-price">
            $ {{ stats.thisMonthDone.toLocaleString('en-CA') }}
          </div>
          <div v-else class="dashboard__block-price">$ 0</div>
        </div>
        <div class="dashboard__block">
          <div class="dashboard__block-title-wrapper">
            <div class="dashboard__block-title">This Year Done</div>
            <div class="dashboard__block-indicator dashboard__block-indicator--blue"></div>
          </div>
          <div v-if="stats.thisYearDone" class="dashboard__block-price">
            $ {{ stats.thisYearDone.toLocaleString('en-CA') }}
          </div>
          <div v-else class="dashboard__block-price">$ 0</div>
        </div>
      </div>
      <div class="dashboard__main-middle">
        <div class="dashboard__stats">
          <div class="dashboard__stats-header">
            <div
              class="dashboard__stats-link"
              :class="{'dashboard__stats-link--active': statsComponent === 'week'}"
              @click="changeStats('week')"
            >
              This Week
              <div v-if="statsComponent === 'week'" class="dashboard__stats-link--line"></div>
            </div>
            <div
              class="dashboard__stats-link"
              :class="{'dashboard__stats-link--active': statsComponent === 'month'}"
              @click="changeStats('month')"
            >
              This Month
              <div v-if="statsComponent === 'month'" class="dashboard__stats-link--line"></div>
            </div>
            <div
              class="dashboard__stats-link"
              :class="{'dashboard__stats-link--active': statsComponent === 'year'}"
              @click="changeStats('year')"
            >
              This Year
              <div v-if="statsComponent === 'year'" class="dashboard__stats-link--line"></div>
            </div>
          </div>
          <!-- <el-tabs v-model="statsComponent" class="dashboard-tabs" @tab-click="changeStats">
            <el-tab-pane class="dashboard__stats-link" label="This Week" name="week"></el-tab-pane>
            <el-tab-pane class="dashboard__stats-link" label="This Month" name="month"></el-tab-pane>
            <el-tab-pane class="dashboard__stats-link" label="This Year" name="year"></el-tab-pane>
          </el-tabs> -->
          <div v-if="statsComponent === 'week'" class="dashboard__stats-main">
            <div v-for="(day, i) of daysOfWeek" :key="i" class="dashboard__stats-row">
              <div class="dashboard__stats-row-title">{{ day }}</div>
              <div class="dashboard__stats-row-price">$ {{ weekStats[i] }}</div>
            </div>
          </div>
          <div v-else-if="statsComponent === 'month'" class="dashboard__stats-main">
            <div v-for="(weekName, i) of weekOfMonth" :key="i" class="dashboard__stats-row">
              <div class="dashboard__stats-row-title">{{ weekName }}</div>
              <div class="dashboard__stats-row-price">$ {{ monthStats[i] }}</div>
            </div>
          </div>
          <div v-else-if="statsComponent === 'year'" class="dashboard__stats-main">
            <div v-for="(montName, i) of monthsOfYear" :key="i" class="dashboard__stats-row">
              <div class="dashboard__stats-row-title">{{ montName }}</div>
              <div class="dashboard__stats-row-price">$ {{ yearStats[i] }}</div>
            </div>
          </div>
        </div>
        <div class="dashboard__chart">
          <apexchart type="line" :options="chartOptions" :series="series" height="400"></apexchart>
        </div>
      </div>
      <div class="dashboard__main-finance">
        <div class="finance__title">
          <span>Select Year</span>
          <date-picker
            v-model="financeYear"
            type="year"
            format="YYYY"
            value-type="format"
            :editable="false"
            class="dashboard-year"
            popup-class="dashboard-year"
            placeholder="Select year"
            @change="selectYear"
          ></date-picker>
        </div>
        <div class="finance__table">
          <div class="finance__table-header">
            <div class="finance__table-header-cell">Month</div>
            <div class="finance__table-header-cell">Service Total</div>
            <div class="finance__table-header-cell">HST Total</div>
            <div class="finance__table-header-cell is-last">Grand Total</div>
          </div>
          <div class="finance__table-main">
            <div v-for="(fin, idx) of financeStats" :key="idx + fin.label" class="finance__table-main-row">
              <div class="finance__table-main-cell">{{ fin.label }}</div>
              <div class="finance__table-main-cell">{{ fin.serviceTotal }} $</div>
              <div class="finance__table-main-cell">{{ fin.hstTotal }} $</div>
              <div class="finance__table-main-cell is-last">{{ fin.grandTotal }} $</div>
            </div>
          </div>
        </div>
        <div class="finance__cards">
          <div v-for="(fin, idx) of financeStats" :key="idx + fin.label" class="finance__card">
            <div class="finance__card-title">{{ fin.label }}</div>
            <div class="finance__card-row">
              <div class="finance__card-cell is-title">Service Total</div>
              <div class="finance__card-cell">{{ fin.serviceTotal }} $</div>
            </div>
            <div class="finance__card-row">
              <div class="finance__card-cell is-title">HST Total</div>
              <div class="finance__card-cell">{{ fin.hstTotal }} $</div>
            </div>
            <div class="finance__card-row">
              <div class="finance__card-cell is-title">Grand Total</div>
              <div class="finance__card-cell">{{ fin.grandTotal }} $</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import {mapState, mapGetters} from 'vuex'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'Dashboard',
  components: {
    apexchart: VueApexCharts,
    DatePicker
  },
  data() {
    return {
      moment,
      financeYear: moment().format('YYYY'),
      statsComponent: 'week',
      week: [],
      month: [],
      year: [],
      daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      weekOfMonth: ['First Week', 'Second Week', 'Third Week', 'Fourth Week'],
      monthsOfYear: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      series: [
        {
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          brush: {
            enabled: false,
            target: undefined,
            autoScaleYaxis: false
          }
        },
        stroke: {
          curve: 'smooth'
        },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex}) {
            return (
              '<div class="chart__tooltip">' + '<span>$ ' + series[seriesIndex][dataPointIndex] + '</span>' + '</div>'
            )
          }
        },
        xaxis: {
          categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          tooltip: {
            enabled: false
          }
        },
        responsive: [
          {
            breakpoint: 413,
            options: {
              xaxis: {
                labels: {
                  rotateAlways: true
                }
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      stats: s => s.dashboard.stats
    }),
    ...mapGetters({
      weekStats: 'dashboard/weekStats',
      monthStats: 'dashboard/monthStats',
      yearStats: 'dashboard/yearStats',
      financeStats: 'dashboard/financeStats'
    })
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('dashboard/fetch'),
      this.$store.dispatch('dashboard/fetchWeekStats'),
      this.$store.dispatch('dashboard/fetchMonthStats'),
      this.$store.dispatch('dashboard/fetchYearStats'),
      this.selectYear(this.financeYear)
    ])
    this.series = [{data: this.weekStats}]
    this.chartOptions = {xaxis: {categories: this.daysOfWeek}}
  },
  methods: {
    changeStats(tab) {
      this.statsComponent = tab
      if (tab === 'week') {
        this.series = [{data: this.weekStats}]
        this.chartOptions = {xaxis: {categories: this.daysOfWeek}}
      } else if (tab === 'month') {
        this.series = [{data: this.monthStats}]
        this.chartOptions = {xaxis: {categories: this.weekOfMonth}}
      } else if (tab === 'year') {
        this.series = [{data: this.yearStats}]
        this.chartOptions = {xaxis: {categories: this.monthsOfYear}}
      }
    },
    async selectYear(year) {
      const yearStart = moment()
        .year(year)
        .startOf('year')
        .format('YYYY-MM-DD')

      const yearEnd = moment()
        .year(year)
        .endOf('year')
        .format('YYYY-MM-DD')

      const range = {
        from: yearStart,
        till: yearEnd
      }
      await this.$store.dispatch('dashboard/fetchFinanceStats', range)
    }
  }
}
</script>
<style lang="scss">
.chart__tooltip {
  padding: 6px 12px;
}
</style>
<style lang="scss" scoped>
@import 'style';
</style>
